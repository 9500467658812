<div *ngIf="loginDisplay" class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<h4 class="title">Listar arquivos</h4>
		<div class="input-group search w-25" style="margin-right: 0">
			<input class="form-control" type="text" placeholder="Pesquisar" [(ngModel)]="listFilter" />
			<div class="input-group-append">
				<button type="button" class="btn-input-customer" arial-label="Close" (click)="cleanFiltered()">
					<em i-bs name="x"></em>
				</button>
			</div>
		</div>
	</div>
	<div class="card-header card-header-sub-customer p-0 d-flex flex-column" *ngIf="(usuario?.grupo)!.empresas.length > 0">
		<div class="progress m-0" *ngIf="fileUpload?.status === 'progress'">
			<div
				class="progress-bar progress-bar-striped"
				role="progressbar"
				[style.width.%]="fileUpload.message"
				aria-valuenow="10"
				aria-valuemin="0"
				aria-valuemax="100"
			>
				{{ fileUpload.message }}
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-between p-4 my-4">
			<form [formGroup]="profileForm" (ngSubmit)="onCreate()" class="d-flex align-items-center">
				<div class="form-group d-flex m-0">
					<label for="profile">Selecionar</label>
					<input
						#fileInput
						type="file"
						class="form-control-file"
						name="profile"
						id="profile"
						(change)="onSelectedFile($event)"
						patterFile="/\d{1,3}\_.+\_(?![a-zA-Z])(\d{1,6})\"
						accept=".txt, .xlsx, .xls"
					/>
					<input id="input-name-file" type="text" formControlName="name" [placeholder]="nameInputFile" readonly />
				</div>
				<div class="form-group m-0">
					<button class="btn-upload" [disabled]="isDisabled">
						<em i-bs name="cloud-upload-fill"></em>
					</button>
				</div>
			</form>
			<button
				type="button"
				id="todos"
				class="btn btn-customer-info btn-customer-export"
				ngbTooltip="Exporta todos arquivos compactados"
				(click)="downloadZip($event)"
			>
				<em i-bs name="arrow-bar-down" width="20" height="20"></em>
			</button>
		</div>
		<div>
			<div *ngIf="messageService.messages.length > 0">
				<div class="alert alert-danger m-0 px-2 py-1" role="alert" style="border-radius: 0">
					<span *ngFor="let message of messageService.messages">{{ message }}</span>
				</div>
			</div>
			<div *ngIf="fileUpload?.status === 'error'">
				<div class="alert alert-danger m-0 px-2 py-1" role="alert" style="border-radius: 0">{{ fileUpload.message }} - {{ error }}</div>
			</div>
		</div>
	</div>
	<div class="card-body h-auto">
		<div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 1">
			<strong>{{ infoMessage.message }}</strong>
		</div>

		<div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 2">
			<div class="spinner-border text-primary" role="status"></div>
		</div>

		<div class="table-responsive" *ngIf="arquivos && infoMessage.status == 3">
			<table class="table table-sm table-hover align-middle" summary="Upload">
				<thead class="table-light">
					<tr>
						<th scope="col">Arquivo</th>
						<th scope="col">Data</th>
						<th scope="col">Hora</th>
						<th scope="col">Baixar</th>
					</tr>
				</thead>

				<tbody>
					<tr *ngFor="let arquivo of pagefiltered; let i = index">
						<td class="align-middle">
							<ngb-highlight [result]="nameFileFormatedTable(arquivo.Nome)" [term]="filter.value!"></ngb-highlight>
						</td>
						<td class="align-middle">
							<ngb-highlight [result]="arquivo.DataCriacao | date : 'dd-MM-yyyy'" [term]="filter.value!.toString()"> </ngb-highlight>
						</td>
						<td class="align-middle">
							<ngb-highlight [result]="arquivo.DataCriacao | date : 'HH:mm:ss'" [term]="filter.value!"></ngb-highlight>
						</td>
						<td class="align-middle">
							<a
								href="{{ arquivo.Url }}"
								class="btn btn-customer-info btn-customer-export d-flex justify-content-center align-items-center"
								(click)="download(arquivo)"
							>
								<em i-bs name="arrow-bar-down" width="20" height="20"></em>
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card-footer-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<ngb-pagination
			class="pagination-customer"
			[collectionSize]="collectionSize"
			[(page)]="page"
			[pageSize]="pageSize"
			(pageChange)="refreshPage()"
		></ngb-pagination>

		<select class="pagination-select-customer" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshPage()">
			<option [ngValue]="50">50 items por página</option>
			<option [ngValue]="100">100 items por página</option>
			<option [ngValue]="150">150 items por página</option>
		</select>
	</div>
</div>

<div *ngIf="fileUpload?.status === 'success'">
	<ng-template #modalAlert let-modal>
		<div class="modal-header">
			<h4 class="title" id="modal-basic-title">Alerta Upload</h4>
			<button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
				<em i-bs name="x" width="20" height="20"></em>
			</button>
		</div>
		<div class="modal-body">
			<fieldset>
				<legend>Registro importado com sucesso!</legend>
			</fieldset>
		</div>
	</ng-template>
</div>
