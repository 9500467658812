<div id="sidebar" class="d-flex flex-column flex-shrink-0 bg-light">
	<ul *ngIf="userExists" class="nav nav-pills nav-flush flex-column mb-auto text-center">
		<li class="nav-item">
			<a [routerLink]="['/home']"
				 class="nav-link align-middle py-3 border-bottom "
				 [routerLinkActive]="['active']"
				 [routerLinkActiveOptions]="{ exact: true }"
				 aria-current="page"
				 ngbTooltip="Home"
				 placement="right">
         <em i-bs name="house-fill" width="24" height="24" class="icon-navibar"></em>
			</a>
		</li>
		<li>
			<a [routerLink]="['/upload']"
				 class="nav-link align-middle py-3 border-bottom"
				 [routerLinkActive]="['active']"
				 [routerLinkActiveOptions]="{ exact: true }"
				 ngbTooltip="Enviar/Receber Arquivos"
				 placement="right">
         		<em i-bs name="file-earmark-arrow-up-fill" width="20" height="20"></em>
			</a>
		</li>
		<li>
			<a [routerLink]="['/arquivosenviados']"
				 class="nav-link align-middle py-3 border-bottom"
				 [routerLinkActive]="['active']"
				 [routerLinkActiveOptions]="{ exact: true }"
				 ngbTooltip="Arquivos Enviados"
				 placement="right">
         		<em i-bs name="file-earmark-text-fill" width="20" height="20"></em>
			</a>
		</li>
	</ul>
</div>