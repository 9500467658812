import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { LoginService } from 'src/app/shared/login.service'

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  userExists: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.loginService.user$.subscribe((user) => {
        this.userExists = !!user; // Verifica se o usuário existe
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
