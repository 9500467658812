import { Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UsuarioService } from 'src/app/shared/usuario.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService implements OnDestroy {
  authenticated: boolean = false;
  private readonly destroy$ = new Subject<void>();

  private userSubject = new BehaviorSubject<any>(null);
  user$ = this.userSubject.asObservable();

  private authenticatedSubject = new BehaviorSubject<boolean>(false);
  authenticated$ = this.authenticatedSubject.asObservable();

  private errorSubject = new BehaviorSubject<string | null>(null);
  error$ = this.errorSubject.asObservable();

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private usuarioService: UsuarioService
  ) {
    this.listenForAuthenticationChanges();
  }

  private listenForAuthenticationChanges() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.loadUserProfile();
        this.updateAuthenticationState();
      });

    this.loadUserProfile();
    this.updateAuthenticationState();
  }

  private updateAuthenticationState() {
    const isAuthenticated = this.msalService.instance.getAllAccounts().length > 0;
    this.authenticatedSubject.next(isAuthenticated);
  }

  private async loadUserProfile() {
    const accounts = this.msalService.instance.getAllAccounts();

    if (accounts && accounts.length > 0) {
      try {
        const res = await this.usuarioService
          .getEmailUser(accounts[0].username)
          .toPromise();

        if (res && res.usuario) {
          this.userSubject.next(res.usuario);
          this.errorSubject.next(null); // Sem erros
        } else {
          // Caso o usuário não esteja cadastrado
          this.userSubject.next(null);
          this.errorSubject.next('O e-mail do usuário não está cadastrado no PTA.');
        }
      } catch (error) {
        console.error('Erro ao obter dados do usuário:', error);
        this.userSubject.next(null);
        this.errorSubject.next('Erro ao verificar o cadastro do usuário no PTA.');
      }
    } else {
      this.userSubject.next(null);
      this.errorSubject.next('Usuário não autenticado.');
    }
  }

  logout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: window.location.origin + '/',
    });
    localStorage.removeItem('token');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
