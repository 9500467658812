import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'

import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'

import { RegistroAtividade } from 'src/app/models/registroAtividade.model'
import { Usuario } from 'src/app/models/usuario.model'
import { ListUserService } from 'src/app/shared/listUser.service'
import { LoginService } from 'src/app/shared/login.service'

import { ArquivosEnviadosService } from './arquivosenviados.service'
import { Subscription } from 'rxjs'

@Component({
	selector: 'app-arquivosEnviados',
	templateUrl: './arquivosenviados.component.html',
	styleUrls: ['./arquivosenviados.component.scss'],
})
export class ArquivosEnviadosComponent implements OnInit, OnDestroy {
	registroAtividades: Array<RegistroAtividade> = []
	registroAtividade: RegistroAtividade
	dataSaved: boolean = false
	usuario: Usuario

	infoMessage = { status: 0, message: 'Não existem arquivos enviando para esse grupo!' }

	modalRef: NgbModalRef

	// search
	_listFilter: string
	filtered: Array<RegistroAtividade> = []
	filter = new FormControl('')

	// paginação
	page = 1
	pageSize = 100
	collectionSize: number
	pagefiltered: Array<RegistroAtividade> = []
	order: string = 'dataHora'

	loginDisplay = false
	private subscriptions: Subscription[] = []

	constructor(
		private listUserService: ListUserService,
		private arquivosEnviadosService: ArquivosEnviadosService,
		private loginService: LoginService,
		private toastrService: ToastrService,
		private router: Router
	) {
		this.loginDisplay = this.loginService.authenticated
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.loginService.user$.subscribe((user) => {
				this.usuario = user
				this.loginDisplay = !!user
				this.getRegisterActivityForUser()
			}),
			this.loginService.error$.subscribe((error) => {
				this.infoMessage.message = error || ''
			})
		)

		this.listUserService.listarUser()
	}

	toAddZero(numero) {
		if (numero <= 9) return '0' + numero
		else return numero
	}

	get listFilter(): string {
		return this._listFilter
	}

	set listFilter(value: string) {
		this.filtered = this.registroAtividades
		this._listFilter = value
		this.filtered = this.listFilter ? this.onFilter(this.listFilter) : this.registroAtividades
		this.collectionSize = this.filtered.length
		this.pagefiltered = this.filtered.sort((a, b) => (a._ts! < b._ts! ? 1 : -1))
		this.refreshPage()
	}

	onFilter(text: string): Array<RegistroAtividade> {
		return this.registroAtividades.filter((registro) => {
			var date = this.formatedData(registro)
			const term = text.toLowerCase()
			return (
				registro.nomeArquivo.toLowerCase().includes(term) ||
				registro.nomeProcesso.toLowerCase().includes(term) ||
				registro.usuario.toString().toLowerCase().includes(term) ||
				// @ts-ignore: Object is possibly 'null'.
				date.includes(term)
			)
		})
	}

	refreshPage() {
		this.pagefiltered = this.listFilter
			? this.filtered
					.map((registro, i) => ({ index: i + 1, ...registro }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
			: this.registroAtividades
					.map((registro, i) => ({ index: i + 1, ...registro }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	cleanFiltered() {
		this.collectionSize = this.registroAtividades.length
		this.listFilter = ''
		this.refreshPage()
	}

	getRegisterActivityForUser() {
		if (this.usuario) {
			let query = {
				query: `SELECT * FROM r WHERE r.nomeGrupo = '${this.usuario.grupo.nome}' AND r.tipo = 'upload'`,
			}
			this.infoMessage.status = 1
			this.arquivosEnviadosService.getRegistroAtividadePorUsuario(query).subscribe(
				(res) => {
					if (res.status <= 400) {
						this.showAlertInfo(res.statusText)
					} else {
						if (res._count == 0) {
							this.infoMessage.status = 0
						} else {
							this.infoMessage.status = 3
							this.registroAtividades = res.Documents.filter((registro) => registro.usuario != 'JobVerificacaoArquivos' && registro.usuario != 'Integração')
							this.listFilter
							this.cleanFiltered()
						}
					}
				},
				(error) => {
					let erro: number = error || error.message
				}
			)
		}
	}

	userFormated(registro) {
		return registro.usuario.nome != null ? registro.usuario.nome : registro.usuario
	}

	formatedData(register) {
		var dataHora = register.dataHora.split(' ')
		var date = dataHora[0].split('/')
		var hour = dataHora[1].split(':')
		let dateFormated = `${this.toAddZero(date[0])}-${this.toAddZero(date[1])}-${date[2]} ${this.toAddZero(hour[0])}:${this.toAddZero(hour[1])}:${this.toAddZero(
			hour[2]
		)}`
		return dateFormated
	}

	showAlertInfo(msg: any) {
		this.toastrService.error(`${msg} - Erro ao executar a chamada, por favor tente mais tarde!`, 'PTA - Portal de transferência de arquivo')
		this.router.navigateByUrl('/arquivosenviados')
		this.modalRef.close()
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe())
	}
}
