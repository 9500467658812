import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/shared/login.service';

const ambiente = environment.descricaoAmbiente;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  imgPath = '../../assets/images/valia-logo.svg';
  user: any = null;
  ambiente: any = ambiente
  private subscription: Subscription;

  constructor(
    private authService: LoginService
  ) {}

  ngOnInit(): void {
    // Inscrevendo-se no observable para obter atualizações do usuário
    this.subscription = this.authService.user$.subscribe((user) => {
      this.user = user;
    });
  }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe(); // Limpar a inscrição
  }

}
