<nav class="w-auto d-flex justify-content-between align-items-center">
	<img src="{{ imgPath }}" width="110" alt="logo" />
	<p class="amb">{{ ambiente }}</p>
	<div class="user-info">
		<p>
			Olá, <span><strong>{{ user?.nome }}</strong></span> </p>
		<button>
			<em i-bs name="arrow-bar-right" width="20" height="20" (click)="onLogout()"></em>
		</button>
	</div>
</nav>
