<div class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-4 d-flex justify-content-center align-items-center">
		<h3 class="title"><strong>PTA - Portal de Transferência de Arquivos</strong></h3>
	</div>

	<div class="card-body p-5">
		<div class="row" *ngIf="mensagem">
			<div class="col-sm-12">
				<p [innerHTML]="textoMensagem" class="text-secondary" style="font-weight: 500"></p>
			</div>
		</div>
	</div>
</div>
